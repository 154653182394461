import {graphql, useStaticQuery} from "gatsby";

export default () => {
    try {
        return useStaticQuery(graphql`
            query {
                reviews: allWordpressPost(filter: {path_category: {eq: "/reviews/"}, to_live: {eq: "1"}}, sort: {order: DESC, fields: date}, limit: 3) {
                    edges {
                      node {
                        title
                        featured_media_srcset
                        excerpt
                        small_date
                        path
                      }
                    }
                }  
            }
        `);
    } catch (error) {
        console.log(error);

        return null;
    }
}
