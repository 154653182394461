import React from "react";
import Row from "react-bootstrap/Row";
import {Link} from "gatsby";
import ROUTES from "../../../configs/route_final";
import Review from "./review.component";

const ReviewsRow = (props) => (
    <div className="reviews-row">
        <Row className="justify-content-center">
            <div className="col-12 col-lg-8">
                <Row className="d-none d-md-flex">
                    <div className="col-5 review-title-wrapper">
                        <img className="reviews-row-icon lazyload" id="desktop-review-icon"
                             data-src="https://images.emit.reviews/nav_icon_review.svg"
                             alt="best"
                        />
                        <svg className="reviews-row-icon" id="mobile-review-icon">
                            <use xlinkHref="/images/nav_icon_review-white.svg#review"/>
                        </svg>
                        <p className="reviews-row-title">Reviews</p>
                        <p className="reviews-row-description">
                            I test each web hosting provider, so you can only use the good ones. Here's what they are.
                        </p>
                        <Link to={ROUTES.reviews.path} className="heading-link">View all reviews</Link>
                    </div>
                    <div className="col-6">
                        {
                            props.reviews.edges.map((element, index) => {
                                return (
                                    <Review title={element.node.title} image={element.node.featured_media_srcset}
                                            link={element.node.path} key={'review' + index} index={index}/>
                                )
                            })
                        }
                    </div>
                </Row>
                <Row className="d-md-none review-mobile">
                    <div className="col-12 review-title-wrapper">
                        <img className="reviews-row-icon lazyload" id="desktop-review-icon"
                             data-src="https://images.emit.reviews/nav_icon_review.svg"
                             alt="review"
                        />
                        <svg className="reviews-row-icon" id="mobile-review-icon">
                            <use xlinkHref="/images/nav_icon_review-white.svg#review"/>
                        </svg>
                        <p className="reviews-row-title">Reviews</p>
                        <p className="reviews-row-description">
                            I test each web hosting provider, so you can only use the good ones. Here's what they are.
                        </p>
                    </div>
                    <div className="col-12">
                        {
                            props.reviews.edges.map((element, index) => {
                                return (
                                    <Review title={element.node.title} image={element.node.featured_media_srcset}
                                            link={element.node.path} key={'review-mobile-' + index} index={index}/>
                                )
                            })
                        }
                    </div>
                    <Link to={ROUTES.reviews.path} className="heading-link">View all reviews</Link>
                </Row>
            </div>
        </Row>
    </div>
);

export default ReviewsRow
