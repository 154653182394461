import React, {useEffect} from 'react';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Layout from "../../layout/layout.component";
import SEO from "../../partials/seo/seo.component";
import FAQCollapseHookClass from "../../hooks/faq-collapse";
import Heading from "./partials/heading.component";
import Infographic from "./partials/infographic.component";
import ReviewsRow from "./partials/ReviewsRow.component";
import Tutorials from "./partials/tutorials.component";
import BestRow from "./partials/BestRow.component";
import Discounts from "./partials/discounts.component";
import PostsByCatgeory from "../../services/api/PostsByCatgeory";

const GetPostsByCatgeoryService = new PostsByCatgeory();
const FAQCollapseHook = new FAQCollapseHookClass();

const HomePage = (props) => {
    const schema = props.pageContext.schema,
        post = props.data.wordpressPage;
    const reviewPosts = GetPostsByCatgeoryService.getReviewPostsLimit3();

    useEffect(() => {
        FAQCollapseHook.load();

        return () => {
            FAQCollapseHook.remove();
        };
    });

    return (
        <Layout isHomePage={true}>
            <SEO data={schema}/>

            <Container className="homepage-wrapper">
                <Heading title={post.title} />
                <Infographic />
                <ReviewsRow reviews={reviewPosts} />
                <Tutorials />
                <BestRow />
                <Discounts />
            </Container>
        </Layout>
    );
};

HomePage.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
    }),
    data: PropTypes.shape({
        wordpressPage: PropTypes.shape({
            title: PropTypes.string.isRequired,
            small_date: PropTypes.string.isRequired,
            front_template: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    }),
};

export default HomePage;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            front_template
            small_date
        }
    }
`;
