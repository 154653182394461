import React from "react";
import PropTypes from "prop-types";
import ROUTES from "../../../configs/route_final";
import {Link} from "gatsby";

const Heading = (props) => (
    <div className="heading-wrapper">
        <h1 className="heading-title">{props.title}</h1>
        <p className="heading-text">Get all the top web hosting information – with reviews, big discounts, and helpful tips all in one place.</p>
        <Link className="heading-link" to={ROUTES.best.path}>Tell me more</Link>
    </div>
);

Heading.propTypes = {
    title: PropTypes.string.isRequired
};

export default Heading
