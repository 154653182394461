import React from "react";
import Row from "react-bootstrap/Row";
import {Link} from "gatsby";
import ROUTES from "../../../configs/route_final";

const Discounts = (props) => (
    <div className="discounts">
        <Row className="justify-content-md-center text-md-center mx-0">
            <div className="col-12 col-lg-8 discounts-wrapper">
                <img className="discounts-image lazyload"
                     data-src="https://images.emit.reviews/discounts-illustration.svg"
                     alt="discounts"
                />
                <svg className="discounts-icon">
                    <use xlinkHref="/images/nav_icon_discounts.svg#discounts"/>
                </svg>
                <p className="discounts-title">Web Hosting Discounts</p>
                <p className="discounts-text">
                    Looking to save? This handpicked list will help you get the best price for your web hosting.
                </p>
                <Link to={ROUTES.discounts.path} className="heading-link discounts-button">Get Discounts</Link>
            </div>
        </Row>
    </div>
);

export default Discounts
