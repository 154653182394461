import ReviewsCategoryPostsWith3LimitQuery from "./ReviewPostsWith3LimitQuery";

class PostsByCatgeory {
    /**
     * @returns {Object|null}
     */
    getReviewPostsLimit3 () {
        try {
            let data = ReviewsCategoryPostsWith3LimitQuery();

            return data.reviews;
        } catch (error) {
            console.log(error);

            return null;
        }
    }
}

export default PostsByCatgeory
