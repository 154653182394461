import React from "react";

const Infographic = (props) => (
    <div className="infographic">
        <h2 className="infographic-title">How do I create content?</h2>
        <img className="infographic-image lazyload" id="infographic-dark-line"
             data-src="https://images.emit.reviews/infographic.svg"
             alt="infographic"
        />
        <img className="infographic-image lazyload" id="infographic-white-lines"
             data-src="https://images.emit.reviews/infographic-white.svg"
             alt="infographic"
        />
        <img className="infographic-image lazyload" id="infographic-mobile"
             data-src="https://images.emit.reviews/mobile-infographic.svg"
             alt="infographic"
        />
    </div>
);

export default Infographic
