import React from "react";
import Row from "react-bootstrap/Row";
import {Link} from "gatsby";
import ROUTES from "../../../configs/route_final";

const Tutorials = (props) => (
    <div className="tutorials">
        <Row className="justify-content-md-center text-md-center mx-0">
            <div className="col-12 col-lg-8 tutorials-wrapper">
                <img className="tutorials-icon lazyload"
                     data-src="https://images.emit.reviews/nav_icon_tutorials_v2.svg"
                     alt="tutorial"
                />
                <p className="tutorials-title">Tutorials</p>
                <p className="tutorials-text">
                    Every so often both complete beginners and seasoned pros can use some help. I got you.
                </p>
                <Row className="tutorials-articles-wrapper">
                    <img className="tutorials-articles-icon tutorials-wordpress lazyload"
                         data-src="https://images.emit.reviews/wordpress.svg"
                         alt="tutorial"
                    />
                    <img className="tutorials-articles-icon tutorials-wordpress lazyload"
                         data-src="https://images.emit.reviews/mouse.svg"
                         alt="tutorial"
                    />
                    <img className="tutorials-articles-icon tutorials-wordpress lazyload"
                         data-src="https://images.emit.reviews/controller.svg"
                         alt="tutorial"
                    />
                </Row>
                <Link to={ROUTES.tutorials.path} className="heading-link tutorials-button">View all tutorials</Link>
            </div>
        </Row>
    </div>
);

export default Tutorials
