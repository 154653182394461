import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {getImage} from "../../../helpers";

const Review = (props) => {
    let imageLink = getImage(props.image, "review-image",  props.title);

    return (
        <Link to={props.link} className="review-wrapper" key={'review-' + props.index}>
            <div className="reviews">
                <div className="">
                    {imageLink}
                </div>
                <div className="">
                    <p className="review-title">{props.title}</p>
                </div>
            </div>
        </Link>
    );
};

Review.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default Review
