import React from "react";
import Row from "react-bootstrap/Row";
import {Link} from "gatsby";
import ROUTES from "../../../configs/route_final";

const BestRow = (props) => (
    <div className="best-row">
        <Row className="justify-content-center">
            <div className="col-12 col-lg-8 best-row-wrapper">
                <Row className="d-none d-md-flex">
                    <div className="col-5 best-row-title-wrapper">
                        <img className="best-row-icon lazyload" id="best-dark"
                             data-src="https://images.emit.reviews/nav_icon_bestfor.svg"
                             alt="best"
                        />
                        <img className="best-row-icon lazyload" id="best-white"
                             data-src="https://images.emit.reviews/nav_icon_bestfor-white.svg"
                             alt="best"
                        />
                        <p className="best-row-title">Best for...</p>
                        <p className="best-row-description">
                            No mission is too small! Here I rank the top products for many diverse categories.
                        </p>
                        <Link to={ROUTES.best.path} className="heading-link">View all lists</Link>
                    </div>
                    <div className="col-6">
                        <img className="best-image lazyload" id="best-dekstop"
                             data-src="https://images.emit.reviews/best-for-illustration.svg"
                             alt="best"
                        />
                    </div>
                </Row>
                <Row className="d-md-none best-row-mobile">
                    <div className="best-row-title-wrapper">
                        <img className="best-row-icon lazyload" id="best-white"
                             data-src="https://images.emit.reviews/nav_icon_bestfor-white_v2.svg"
                             alt="best"
                        />
                        <p className="best-row-title">Best for</p>
                        <p className="best-row-description">
                            No mission is too small! Here I rank the top products for many diverse categories.
                        </p>
                    </div>
                    <Link to={ROUTES.best.path} className="heading-link">View all lists</Link>
                </Row>
            </div>
        </Row>
    </div>
);

export default BestRow
